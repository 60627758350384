import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

export const Wrapper = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  font-family: 'Nunito Sans', sans-serif;
  display: inline-block;

  @media screen and ${BASE_CONF.screens.lg} {
    max-width: 462px;
  }
`

export const Label = styled.label`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #14181c;
  margin-bottom: 16px;
`

export const TextAreaInputWrapper = styled.div`
  border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #d2d9df')};
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;

  :focus-within {
    outline: none;
    border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #14181c')};
  }
`

export const TextArea = styled.textarea`
  font-family: 'Nunito Sans', sans-serif;
  background: #ffffff;
  border: none;
  padding: 19px 16px;
  font-size: 16px;
  width: 100%;
  resize: ${props =>
    props.resizeVertical && props.resizeHorizontal
      ? 'both'
      : props.resizeVertical
      ? 'vertical'
      : props.resizeHorizontal
      ? 'horizontal'
      : 'none'};

  ::placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #4a5968;
  }

  &::-webkit-scrollbar {
    background: #d2d9df;
    border-radius: 24px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #798c9f;
    border-radius: 24px;
  }

  :focus {
    outline: none;
  }
`

export const HelperWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`

export const HelperText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #4a5968;
`

export const ErrorText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #c82853;
`
