import React, {forwardRef} from 'react'

import NumberFormat from 'react-number-format'

import {getCurrencySymbol} from 'common/helpers/money'
import {CURRENCY_SYMBOLS} from 'consts.json'

import * as S from './Styled'

const Base = forwardRef((props, ref) => {
  const {getFieldValue, setFieldValue, register, isTouched, ...otherProps} = props

  const hasError = props.error && (isTouched || props.untouchedError)
  const currencySymbol =
    props.currencySymbol || getCurrencySymbol(props.currency) || CURRENCY_SYMBOLS.EUR

  let HelperComponent = props.helperText ? <S.HelperText>{props.helperText}</S.HelperText> : null

  if (hasError) {
    HelperComponent = <S.ErrorText>{props.error.message}</S.ErrorText>
  }

  return (
    <S.Wrapper className={props.className} isDisabled={props.isDisabled} hasError={hasError}>
      {props.label ? <S.Label htmlFor={props.id || props.name}>{props.label}</S.Label> : null}
      <NumberFormat
        ref={ref}
        {...otherProps}
        thousandSeparator={props.thousandSeparator || true}
        prefix={currencySymbol}
        allowNegative={props.allowNegative || false}
      />
      {HelperComponent ? (
        <S.HelperWrapper className={'helper-text-wrapper'}>{HelperComponent}</S.HelperWrapper>
      ) : null}
    </S.Wrapper>
  )
})

export default Base
