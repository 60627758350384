import React, {forwardRef} from 'react'

import {observer, useModelContext} from 'lib/model'

import InputIcon from '../../../InputIcon'

import * as S from './Styled'

const DEFAULT_CLOSE_ICON_PATH = '/static/images/close-btn-gray.svg'

const Input = forwardRef((props, ref) => {
  const {model} = useModelContext()
  const isCloseIconShown = 'isCloseIconShown' in props ? props.isCloseIconShown : true

  return (
    <S.Wrapper>
      <InputIcon
        ref={ref}
        autoComplete="off"
        onChange={model.handleOnChange}
        onRightIconClick={() => {
          if (model.hideResultContainer) {
            model.handleOnFocus()
          } else {
            model.hideResultContainer = true
          }
        }}
        {...props}
      />
      {model.showClearButton && (
        <S.CloseIcon
          isShown={isCloseIconShown}
          className="cp-autocomplete-clear-btn"
          onClick={model.handleClear}
          src={props.closeIconPath ? props.closeIconPath : DEFAULT_CLOSE_ICON_PATH}
        />
      )}
    </S.Wrapper>
  )
})

export default observer(Input)
