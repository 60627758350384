import React from 'react'

import Button from '../Button'

import * as S from './Styled'

function Base({items, fieldValue, onChange = () => {}, className, isDisabled = false}) {
  return (
    <S.RadioGroupWrapper
      className={`${className} cp-form-radio-group`}
      onChange={e => onChange(e.target.value)}
    >
      {items.map(item => (
        <S.RadioBtn
          key={item.value}
          control={<Button />}
          label={item.label}
          value={item.value}
          checked={item.value === fieldValue}
          disabled={isDisabled}
        />
      ))}
    </S.RadioGroupWrapper>
  )
}

export default Base
