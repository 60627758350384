import React from 'react'

import {Controller} from 'react-hook-form'

import Base from '../Base'

export default function SelectBox(props) {
  if (!props.control) {
    return <Base {...props} />
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={controllerProps => (
        <Base
          {...props}
          onChange={val => controllerProps.onChange(val.value)}
          value={props.options.find(c => c.value === controllerProps.value)}
          onBlur={controllerProps.onBlur}
          fieldValue={controllerProps.value}
          isDisabled={props.isDisabled}
        />
      )}
    />
  )
}
