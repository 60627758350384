import React from 'react'

import {observer, useModelContext} from 'lib/model'

import * as S from './Styled'

function ResultsContainer({ResultsBeforeComponent, ItemComponent}) {
  const {model} = useModelContext()

  if (!model.shouldShowResultsContainer) {
    return null
  }

  return (
    <S.Wrapper className="cp-autocomplete-results-container">
      <S.Container>
        <ResultsBeforeComponent />
        {model.results.map((result, i) => (
          <S.ComponentWrapper key={i} onClick={() => model.handleResultClick(result)}>
            <ItemComponent data={result} />
          </S.ComponentWrapper>
        ))}
      </S.Container>
    </S.Wrapper>
  )
}

export default observer(ResultsContainer)
