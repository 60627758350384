import {useEffect, useRef} from 'react'
import useOnClickOutside from 'common/hooks/useOnClickOutside'
import useModel from './useModel'

const DEBOUNCE_TIMEOUT = 300

function useLocalState(props) {
  const {model, ModelProvider} = useModel(props)
  const wrapperRef = useRef()

  useOnClickOutside(wrapperRef, () => {
    model.hideResultContainer = true
  })

  useEffect(() => {
    model.getResults()
  }, [model.debouncedValue])

  useEffect(() => {
    const timeoutRef = setTimeout(() => (model.debouncedValue = model.rawValue), DEBOUNCE_TIMEOUT)
    return () => clearTimeout(timeoutRef)
  }, [model.rawValue])

  return {
    model,
    ModelProvider,
    wrapperRef
  }
}

export default useLocalState
