import {parseProxy, useGenerateModel} from 'lib/model/index'

const SEARCH_STARTS_AT = 5
const DEFAULT_FIELD_VALUE_MAPPER = 'title'

function useModel({
  setFieldValue = () => {},
  getAutocompleteResults = () => {},
  handleResultClick = () => {},
  onClear = () => {},
  onChange = () => {},
  getAutocompleteResultsOnFocus = false,
  searchStartAtChar = SEARCH_STARTS_AT,
  fieldValueMapper = DEFAULT_FIELD_VALUE_MAPPER,
  form = {trigger: () => {}},
  disabled = false,
  isValueSelected = false
}) {
  const {model, ModelProvider} = useGenerateModel({
    shouldAutocomplete: true,
    hideResultContainer: true,
    showClearButton: disabled || isValueSelected,
    debouncedValue: null,
    rawValue: null,
    results: [],
    isLoaded: false,
    innerInitialValue: '',
    currentInputValue: '',
    get shouldPerformApiCall() {
      return (
        typeof this.debouncedValue === 'string' &&
        this.debouncedValue.length >= searchStartAtChar &&
        this.shouldAutocomplete
      )
    },
    get shouldShowResultsContainer() {
      return !this.hideResultContainer && this.results?.length > 0
    },
    async handleOnFocus() {
      setFieldValue('')

      if (getAutocompleteResultsOnFocus) {
        this.results = await getAutocompleteResults('')
        this.hideResultContainer = false
      }
    },
    handleResultClick(result) {
      form.trigger()
      this.hideResultContainer = true
      this.showClearButton = true
      setFieldValue(result[fieldValueMapper])
      this.currentInputValue = result[fieldValueMapper]
      handleResultClick(parseProxy(result))
    },
    handleClear() {
      form.trigger()
      this.showClearButton = false
      setFieldValue('')
      this.debouncedValue = ''
      this.innerInitialValue = ''
      this.currentInputValue = ''
      onClear()
    },
    handleOnChange(event) {
      if (!this.shouldAutocomplete) {
        this.shouldAutocomplete = true
      }

      if (event.target.value.length <= 1) {
        this.showClearButton = false
      } else {
        this.hideResultContainer = false
      }

      this.rawValue = event.target.value

      onChange(event)
    },
    handleBlur() {
      setFieldValue(this.currentInputValue)
    },
    async getResults() {
      if (this.shouldPerformApiCall) {
        const resValue = await getAutocompleteResults(this.debouncedValue ?? '')

        if (Array.isArray(resValue)) {
          this.results = resValue
        }

        this.hideResultContainer = false
      } else {
        this.hideResultContainer = true
      }
    }
  })

  return {model, ModelProvider}
}

export default useModel
