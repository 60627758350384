import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  overflow: hidden;
  min-height: 40px;
  padding-bottom: 10px;

  ${props => props.theme.mediaQuery.md} {
    width: 462px;
  }

  border-radius: 12px;
  border: 1px solid ${props => props.theme.colors.secondary2};
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
`

export const ComponentWrapper = styled.div`
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: auto;
  max-height: 296px;
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background: #d2d9df;
    border-radius: 24px;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #798c9f;
    border-radius: 24px;
  }
`
