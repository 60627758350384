import React from 'react'

import Select, {components} from 'react-select'

import Option from './components/Option'

import * as S from './Styled'

function Base({
  className,
  customStyles,
  CustomOption = Option,
  ValueContainer = components.ValueContainer,
  isSearchable = true,
  isClearable = false,
  label = '',
  isDisabled = false,
  ...otherProps
}) {
  return (
    <S.Wrapper className={className}>
      {label ? <S.Label htmlFor={otherProps.id || otherProps.name}>{label}</S.Label> : null}
      <Select
        styles={{...S.customStyles, ...customStyles}}
        components={{Option: CustomOption, ValueContainer}}
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPlacement={'auto'}
        isDisabled={isDisabled}
        {...otherProps}
      />
    </S.Wrapper>
  )
}

export default Base
