import React from 'react'

import {Controller} from 'react-hook-form'

import Base from './Base'

const InputCurrency = props => {
  const isInReactHookForm = !!props.control
  if (!isInReactHookForm) {
    return <Base {...props} />
  }

  return (
    <Controller
      render={controllerProps => <Base {...controllerProps} {...props} />}
      name={props.name}
      control={props.control}
    />
  )
}

export default InputCurrency
