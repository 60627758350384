import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`

export const ErrorText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #c82853;
`

export const InfoText = styled(ErrorText)`
  color: #4a5968;
`
