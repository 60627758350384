import React, {forwardRef} from 'react'

import {Editor} from '@tinymce/tinymce-react'

import InfoOrError from './components/InfoOrError'
import useLogic from './useLogic'

import * as S from './Styled'

const HtmlEditor = (
  {
    className,
    id,
    name,
    initOptions,
    labelText = '',
    placeholder,
    maxCharacterCount,
    error,
    onInit,
    onEditorChange,
    ...otherProps
  },
  editorRef
) => {
  const {
    editorApiKey,
    resolvedInitOptions,
    isError,
    errorMessage,
    infoMessage,
    handleInit,
    handleEditorChange
  } = useLogic({
    initOptions,
    placeholder,
    maxCharacterCount,
    error,
    onInit,
    onEditorChange
  })
  const editorId = id || name
  return (
    <S.Wrapper className={className}>
      {labelText ? <S.Label htmlFor={editorId}>{labelText}</S.Label> : null}
      <S.EditorContainer hasError={isError}>
        <Editor
          id={editorId}
          ref={editor => {
            if (editorRef) {
              editorRef.current = editor
            }
          }}
          apiKey={editorApiKey}
          init={resolvedInitOptions}
          inline={false}
          onInit={handleInit}
          onEditorChange={handleEditorChange}
          {...otherProps}
        />
      </S.EditorContainer>
      <InfoOrError errorText={errorMessage} infoText={infoMessage} />
    </S.Wrapper>
  )
}

export default forwardRef(HtmlEditor)
