import React from 'react'
import BasicHtmlEditor from './Basic'
import FormControlledHtmlEditor from './FormControlled'

const HtmlEditor = props => {
  const isInReactHookForm = !!props.control
  const Editor = isInReactHookForm ? FormControlledHtmlEditor : BasicHtmlEditor

  return <Editor {...props} />
}

export default HtmlEditor
