import styled from 'styled-components'
import {defaultEditorHeight} from './helpers'

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
`

export const Label = styled.label`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #14181c;
  margin-bottom: 16px;
`

export const EditorContainer = styled.div`
  min-height: ${defaultEditorHeight}px;
  border-radius: 8px;
  ${props => (props.hasError ? 'border: 1px solid #c82853;' : '')}
`
