import React, {forwardRef} from 'react'

import {observer} from 'lib/model'

import Input from './components/Input'
import ResultsContainer from './components/ResultsContainer'
import useLocalState from './useLocalState'

import * as S from './styled'

const Autocomplete = forwardRef((props, ref) => {
  const {model, ModelProvider, wrapperRef} = useLocalState(props)
  const {
    initialValue,
    ItemComponent,
    className,
    ResultsBeforeComponent = () => null,
    onClear = () => {},
    ...otherProps
  } = props

  model.innerInitialValue = initialValue

  return (
    <ModelProvider>
      <S.Wrapper ref={wrapperRef} onFocus={model.handleOnFocus} className={className}>
        <Input
          value={model.innerInitialValue}
          ref={ref}
          type="search"
          autocomplete="chrome-off"
          onBlur={model.handleBlur}
          {...otherProps}
        />
        <ResultsContainer
          ResultsBeforeComponent={ResultsBeforeComponent}
          ItemComponent={ItemComponent}
        />
      </S.Wrapper>
    </ModelProvider>
  )
})

export default observer(Autocomplete)
