import styled from 'styled-components'

export const Wrapper = styled.div``

export const Label = styled.label`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #14181c;
  margin-bottom: 16px;
`

export const customStyles = {
  container: style => ({
    ...style,
    fontFamily: '"Nunito Sans", sans-serif'
  }),
  control: style => ({
    ...style,
    cursor: 'pointer',
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #D2D9DF',
    boxSizing: 'border-box',
    borderRadius: '16px',
    height: '56px',
    padding: '0 8px 0 8px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '130%',
    color: '#4A5968',
    ':hover': {
      border: '1px solid #D2D9DF',
      boxShadow: '0 0 0 1px #D2D9DF'
    },
    ':focus-within': {
      boxShadow: '0 0 0 1px #D2D9DF'
    }
  }),
  indicatorSeparator: style => ({
    ...style,
    display: 'none'
  }),
  indicatorContainer: style => ({
    ...style,
    color: '#798c9f',
    cursor: 'pointer'
  }),
  menu: style => ({
    ...style,
    padding: '10px 3px 10px 3px',
    border: '1px solid #D2D9DF',
    boxSizing: 'border-box',
    borderRadius: '16px',
    overflow: 'hidden',
    zIndex: '401'
  }),
  menuList: style => ({
    ...style,
    '::-webkit-scrollbar': {
      background: '#d2d9df',
      borderRadius: '24px',
      width: '3px'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#798c9f',
      borderRadius: '24px'
    }
  }),
  singleValue: style => ({
    ...style,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '130%',
    color: '#4A5968'
  })
}
