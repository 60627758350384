import React from 'react'
import {Controller} from 'react-hook-form'
import Base from './Base'

const Switch = props => {
  if (!props.control) {
    return <Base {...props} />
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={controllerProps => <Base {...props} {...controllerProps} />}
    />
  )
}

export default Switch
