import React from 'react'

import * as S from './Styled'

const InfoOrError = ({errorText = '', infoText = ''}) => {
  if (errorText || infoText) {
    return (
      <S.Container>
        <S.ErrorText>{errorText}</S.ErrorText>
        <S.InfoText>{infoText}</S.InfoText>
      </S.Container>
    )
  }

  return null
}

export default InfoOrError
