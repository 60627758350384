import React, {forwardRef} from 'react'

import * as S from './styled'

const Input = forwardRef((props, ref) => {
  const {
    className,
    label,
    helperText,
    isTouched,
    untouchedError = false,
    error,
    register,
    formik = {fieldProps: {}},
    ...otherProps
  } = props

  const hasError = error && (isTouched || untouchedError)

  let HelperComponent = helperText ? <S.HelperText>{helperText}</S.HelperText> : null

  if (hasError) {
    HelperComponent = <S.ErrorText>{error.message}</S.ErrorText>
  }

  return (
    <S.Wrapper className={className}>
      {label ? (
        <S.Label className="cp-fields-input-label" htmlFor={otherProps.id || otherProps.name}>
          {label}
        </S.Label>
      ) : null}
      <S.Input
        className="cp-form-text-input"
        name={otherProps.name}
        ref={ref || register}
        hasError={hasError}
        {...formik.fieldProps}
        {...otherProps}
      />
      {HelperComponent ? <S.HelperWrapper>{HelperComponent}</S.HelperWrapper> : null}
    </S.Wrapper>
  )
})

export default Input
