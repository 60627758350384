import React, {forwardRef} from 'react'
import Switch from 'components/common/Checkbox'

const Base = forwardRef((props, ref) => {
  const {value = false, ...otherProps} = props

  return (
    <Switch
      ref={ref}
      type="checkbox"
      isOn={value}
      options={{
        onColor: '#f0f2f4',
        offColor: '#f0f2f4',
        offHandleColor: '#ffffff',
        onHandleColor: '#61b7cb',
        uncheckedIcon: false,
        checkedIcon: false
      }}
      {...otherProps}
    />
  )
})

export default Base
