import React from 'react'

import * as S from './styled'

export default function Option(props) {
  const {innerRef, innerProps} = props
  return (
    <S.Wrapper isSelected={props.isSelected} ref={innerRef} {...innerProps}>
      <S.Text>{props.data.label}</S.Text>
    </S.Wrapper>
  )
}
