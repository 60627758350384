import React from 'react'

import {Controller} from 'react-hook-form'

import Base from './Base'

const ButtonGroup = props => {
  if (!props.control) {
    return <Base {...props} />
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={controllerProps => (
        <Base {...props} onChange={controllerProps.onChange} fieldValue={controllerProps.value} />
      )}
    />
  )
}

export default ButtonGroup
