import styled from 'styled-components'

export const Wrapper = styled.div`
  input {
    background: #ffffff;
    border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #d2d9df')};
    box-sizing: border-box;
    border-radius: 16px;
    padding: 19px 16px;
    width: 100%;
    font-size: 16px;
    pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

    :focus {
      outline: none;
      border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #14181c')};
    }

    ::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      color: #97a6b5;
    }
  }
`

export const HelperWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`

export const HelperText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: ${props => props.theme.colors.secondary5};
`

export const ErrorText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #c82853;
`

export const Label = styled.label`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #14181c;
  margin-bottom: 16px;
`

export const DefaultLabelText = styled.span`
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: #000000;
  text-align: left;
  cursor: pointer;
`
