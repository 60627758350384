import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {BASE_CONF} from 'components/common/CPTheme'

export const RadioGroupWrapper = styled(RadioGroup)`
  margin: 48px 0 40px;

  .MuiFormControlLabel-root {
    margin-left: -9px;
  }

  @media screen and ${BASE_CONF.screens.md} {
    margin-bottom: 32px;
  }
`

export const RadioBtn = styled(FormControlLabel)``
