import React from 'react'
import ReactTooltip from 'react-tooltip'
import {v1} from 'uuid'

import * as S from './Styled'

function Tooltip({text, id = v1(), options = {}, children, className}) {
  return (
    <S.Wrapper className={className}>
      <S.Content data-for={id} data-tip={text}>
        {children}
      </S.Content>
      <ReactTooltip
        className="cp-tooltip"
        id={id}
        place={'top'}
        type={'dark'}
        effect={'solid'}
        {...options}
      />
    </S.Wrapper>
  )
}

export default Tooltip
