import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  color: #798c9f;
  margin-right: 4px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 36px;
  display: ${props => (props.isShown ? 'block' : 'none')};
  ${props => props.theme.mediaQuery.xs} {
    right: 10px;
  }
`
