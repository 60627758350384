import React from 'react'

import Radio from '@material-ui/core/Radio'
import clsx from 'clsx'

import {useStyles} from './Styled'

const RadioButton = props => {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIcon, 'cp-radio-checked-icon')} />
      }
      icon={<span className={clsx(classes.icon, 'cp-radio-icon')} />}
      {...props}
    />
  )
}

export default RadioButton
