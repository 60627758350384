import {useState, useEffect} from 'react'

function useLocalState({items, selectedItems, setSelectedItems, removeOption}) {
  const [possibleOptions, setPosibleOptions] = useState(
    items.filter(item => !selectedItems.includes(item.value))
  )

  const handleOnChange = selItem => {
    setSelectedItems([...selectedItems, selItem.value])
  }

  useEffect(() => {
    setPosibleOptions(items.filter(item => !selectedItems.includes(item.value)))
  }, [selectedItems])

  const removeItem = removeOption
    ? removeOption
    : itemVal => {
        setSelectedItems(selectedItems.filter(sel => sel !== itemVal))
      }

  return {possibleOptions, setPosibleOptions, handleOnChange, removeItem}
}

export default useLocalState
