import React, {forwardRef} from 'react'

import Switch from 'react-switch'

const Checkbox = forwardRef((props, ref) => {
  const {isOn = false, onChange = () => {}, options = {}} = props

  const onToggle = checked => {
    onChange(checked)
  }

  return (
    <Switch
      ref={ref}
      height={props.height ? props.height : 32}
      width={props.width ? props.width : 56}
      handleDiameter={props.handleDiameter ? props.handleDiameter : 26}
      borderRadius={48}
      offColor={'#fff'}
      onColor={'#fff'}
      offHandleColor={'#B5BFCA'}
      onHandleColor={'#61B7CB'}
      boxShadow={'#fff'}
      activeBoxShadow={'#fff'}
      {...options}
      className={'react-switch'}
      onChange={onToggle}
      checked={isOn}
    />
  )
})

export default Checkbox
