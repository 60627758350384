import styled from 'styled-components'

export const Wrapper = styled.div`
  .cp-tooltip {
    border-radius: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
  }
`

export const Content = styled.div``
