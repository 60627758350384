import React from 'react'

import * as S from './styled'

function TextArea({
  label,
  helperText,
  className,
  rows,
  register,
  isTouched,
  error,
  resizeVertical = true,
  resizeHorizontal = true,
  untouchedError = false,
  formik = {fieldProps: {}},
  ...props
}) {
  const hasError = error && (isTouched || untouchedError)

  let HelperComponent = helperText ? <S.HelperText>{helperText}</S.HelperText> : null

  if (hasError) {
    HelperComponent = <S.ErrorText>{error.message}</S.ErrorText>
  }

  return (
    <S.Wrapper className={className}>
      {label ? <S.Label htmlFor={props.id || props.name}>{label}</S.Label> : null}
      <S.TextAreaInputWrapper className="cp-textarea-input-wrapper" hasError={hasError}>
        <S.TextArea
          name={props.name}
          rows={rows}
          className="cp-form-text-input"
          maxLength={props.maxCharacters}
          resizeVertical={resizeVertical}
          resizeHorizontal={resizeHorizontal}
          ref={register}
          {...formik.fieldProps}
          {...props}
        />
      </S.TextAreaInputWrapper>
      {HelperComponent ? <S.HelperWrapper>{HelperComponent}</S.HelperWrapper> : null}
    </S.Wrapper>
  )
}

export default TextArea
