import React from 'react'
import Tooltip from 'components/common/Tooltip'
import SelectBox from '../SelectBox'

import useLocalState from './useLocalState'
import * as S from './Styled'

function SelectBoxWithCustomItems({
  className = '',
  Item = () => <div />,
  items = [],
  selectedItems = [],
  setSelectedItems = () => {},
  isDisabled = false,
  tooltipText = '',
  removeOption,
  ...otherProps
}) {
  const {possibleOptions, handleOnChange, removeItem} = useLocalState({
    items,
    selectedItems,
    setSelectedItems,
    removeOption
  })

  return (
    <S.Wrapper className={className}>
      <Tooltip text={tooltipText}>
        <SelectBox
          isDisabled={isDisabled}
          value=""
          onChange={handleOnChange}
          options={possibleOptions}
          {...otherProps}
        />
      </Tooltip>
      <S.ItemsWrapper className="cp-selectbox-custom-items-wrapper">
        {selectedItems.map(selected => (
          <Item key={selected} value={selected} removeItem={() => removeItem(selected)} />
        ))}
      </S.ItemsWrapper>
    </S.Wrapper>
  )
}

export default SelectBoxWithCustomItems
