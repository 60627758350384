import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  align-items: center;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0px;
  cursor: pointer;
  position: relative;
`

export const InnerCheckbox = styled.input`
  opacity: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const DefaultLabelText = styled.span`
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: #000000;
  text-align: left;
  cursor: pointer;
`

export const CheckboxLabel = styled.label``

export const CheckboxIcon = styled.img`
  display: block;
`
