import React, {forwardRef} from 'react'

import {Input} from '../'

import * as S from './styled'

const InputIcon = forwardRef((props, ref) => {
  const {
    leftIconSrc,
    LeftIcon,
    rightIconSrc,
    RightIcon,
    onLeftIconClick,
    onRightIconClick,
    ...otherProps
  } = props

  return (
    <S.Wrapper leftIconExist={leftIconSrc || LeftIcon} rightIconExist={rightIconSrc || RightIcon}>
      {leftIconSrc && (
        <S.LeftIcon
          className="cp-form-icon-text-left-icon"
          onClick={onLeftIconClick}
          src={leftIconSrc}
        />
      )}
      {LeftIcon && (
        <S.LeftIconWrapper
          className="cp-form-icon-text-left-icon-wrapper"
          onClick={onLeftIconClick}
        >
          <LeftIcon />
        </S.LeftIconWrapper>
      )}
      <Input ref={ref} {...otherProps} />
      {rightIconSrc && (
        <S.RightIcon
          className="cp-form-icon-text-right-icon"
          onClick={onRightIconClick}
          src={rightIconSrc}
          shouldMoveDown={'label' in props}
        />
      )}
      {RightIcon && (
        <S.RightIconWrapper
          className="cp-form-icon-text-right-icon-wrapper"
          onClick={onRightIconClick}
        >
          <RightIcon />
        </S.RightIconWrapper>
      )}
    </S.Wrapper>
  )
})

export default InputIcon
