import {useMemo, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'

import {editorApiKey, resolveErrorMessage, resolveInitOptions} from './helpers'

const getContentLength = editor => editor.getContent({format: 'text'}).length

const useLogic = ({
  initOptions,
  placeholder,
  maxCharacterCount,
  error,
  onInit = () => {},
  onEditorChange = () => {}
}) => {
  const [, setIsInitialised] = useState(false)
  const resolvedInitOptions = useMemo(() => resolveInitOptions(initOptions, placeholder), [
    initOptions,
    placeholder
  ])
  const charCountRef = useRef(0)
  const {t} = useTranslation()

  const currentCharCount = charCountRef.current
  const remainingCharCount =
    typeof maxCharacterCount === 'number' ? maxCharacterCount - currentCharCount : undefined
  const isError = error || (typeof remainingCharCount === 'number' && remainingCharCount < 0)
  const errorMessage = resolveErrorMessage(error)
  const infoMessage =
    typeof remainingCharCount === 'number' ? `${remainingCharCount} ${t('CHARACTERS_LEFT')}` : ''

  const handleInit = (event, editor) => {
    const newCharCount = getContentLength(editor)
    charCountRef.current = newCharCount
    onInit(event, newCharCount, editor)
    // This enables char counter GUI update after editor is initialised.
    setIsInitialised(true)
  }

  const handleEditorChange = (newValue, editor) => {
    const newCharCount = getContentLength(editor)
    const shouldUpdateValue =
      typeof maxCharacterCount === 'undefined' ||
      newCharCount <= maxCharacterCount ||
      newCharCount < currentCharCount
    if (shouldUpdateValue) {
      charCountRef.current = newCharCount
      onEditorChange(newValue, newCharCount, editor)
    }
  }

  return {
    editorApiKey,
    resolvedInitOptions,
    isError,
    errorMessage,
    infoMessage,
    handleInit,
    handleEditorChange
  }
}

export default useLogic
