import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
`

export const Label = styled.label`
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #14181c;
  margin-bottom: 16px;
`

export const Input = styled.input`
  background: #ffffff;
  border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #d2d9df')};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 19px 16px;
  width: 100%;
  font-size: 16px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

  :focus {
    outline: none;
    border: ${props => (props.hasError ? '1px solid #c82853' : '1px solid #14181c')};
  }

  ::placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #4a5968;
  }
`

export const HelperWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`

export const HelperText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #4a5968;
`

export const ErrorText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #c82853;
`
