import React from 'react'
import {useController} from 'react-hook-form'
import BaseHtmlEditor from '../Basic'

const HtmlEditor = ({name, control, onEditorChange = () => {}, ...otherProps}) => {
  const {field} = useController({control, name})
  const {onChange, ...otherFieldProps} = field

  return (
    <BaseHtmlEditor
      name={name}
      {...otherProps}
      {...otherFieldProps}
      onEditorChange={(newValue, charCount, editor) => {
        onChange(newValue)
        onEditorChange(newValue, charCount, editor)
      }}
    />
  )
}

export default HtmlEditor
