import React, {forwardRef} from 'react'
import * as S from './styled'

const Base = forwardRef((props, ref) => {
  const {
    name,
    value = false,
    onChange = () => {},
    onWrapperClick,
    Label = '',
    id,
    partChecked,
    emptySrc = '/static/images/sign-up-checkbox-empty.svg',
    checkedSrc = '/static/images/sign-up-checkbox-checked.svg',
    partCheckedSrc = '/static/images/checkmark-minus.svg'
  } = props

  const LabelComponent =
    typeof Label === 'string' ? <S.DefaultLabelText>{Label}</S.DefaultLabelText> : <Label />

  return (
    <S.CheckboxContainer className="cp-form-checkbox-container" onClick={onWrapperClick}>
      <S.CheckboxWrapper className="cp-checkbox-check-wrapper">
        <S.InnerCheckbox
          ref={ref}
          name={name}
          type="checkbox"
          id={id}
          checked={value}
          onChange={e => onChange(e.target.checked)}
        />
        <S.CheckboxIcon
          className="cp-checkox-icon"
          src={partChecked ? partCheckedSrc : value ? checkedSrc : emptySrc}
        />
      </S.CheckboxWrapper>
      <S.CheckboxLabel htmlFor={id || name}>{LabelComponent}</S.CheckboxLabel>
    </S.CheckboxContainer>
  )
})

export default Base
