import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 31px;
  border-radius: 8px;
  padding: 6px 8px 6px 8px;
  margin: 2px 8px 2px 8px;
  cursor: pointer;
  background: ${props => (props.isSelected ? '#f0f2f4' : 'white')};

  :hover {
    background: #f0f2f4;
  }
`

export const Text = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #4a5968;
`
