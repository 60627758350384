import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;

  .cp-form-text-input {
    padding-left: ${props => (props.leftIconExist ? '50px' : '16px')};
    padding-right: ${props => (props.rightIconExist ? '50px' : '16px')};
  }
`

export const Icon = styled.img`
  position: absolute;
  top: ${props => (props.shouldMoveDown ? '52px' : '18px')};
  cursor: pointer;
`

export const LeftIcon = styled(Icon)`
  left: 16px;
`

export const RightIcon = styled(Icon)`
  right: 16px;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 18px;
  cursor: pointer;
`

export const LeftIconWrapper = styled(IconWrapper)`
  left: 16px;
`

export const RightIconWrapper = styled(IconWrapper)`
  right: 16px;
`
