import theme from 'common/helpers/theme'
import config from 'config'

const editorApiKey = config.TINYMCE_API_KEY
const defaultEditorHeight = 250
const {colors} = theme

const defaultStyle = `body {font-family: Nunito Sans,sans-serif; font-size: 16px; font-weight: 400; line-height: 130%;}
  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {font-size: 14px; color: ${colors.secondary7};}
  .mce-content-readonly {background-color: ${colors.secondary1};}`

const defaultInitOptions = {
  content_style: defaultStyle,
  entity_encoding: 'raw',
  height: 250,
  menubar: false,
  plugins: 'lists advlist link code wordcount',
  statusbar: false,
  toolbar: 'undo redo | bold italic | bullist numlist | link code',
  toolbar_mode: 'wrap'
}

const resolveInitOptions = (passedInitOptions = {}, placeholder = '') => ({
  ...defaultInitOptions,
  placeholder,
  ...passedInitOptions
})

const resolveErrorMessage = error => {
  if (error) {
    if (typeof error.message === 'string') {
      return error.message
    }
    if (typeof error === 'string') {
      return error
    }
  }
  return ''
}

export {defaultEditorHeight, editorApiKey, resolveInitOptions, resolveErrorMessage}
